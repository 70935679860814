import styled from "styled-components"
import { CLR_BG1, CLR_BG3 } from "../app/constants"
import { mobile } from "../app/responsive"
import LaptopWindowsIcon from "@mui/icons-material/LaptopWindows"
import TabletAndroidIcon from "@mui/icons-material/TabletAndroid"
import AccessTimeIcon from "@mui/icons-material/AccessTime"
import AttachMoneyIcon from "@mui/icons-material/AttachMoney"

const getTotalDiscounts = (orders) => {
  let total = 0
  orders.forEach(order => {
    let temp = 0
    order.products.forEach(product => {
      temp += parseFloat(product.price)
    })
    total += temp - parseFloat(order.total)
  })
  return total
}

const Container = styled.div`
  width: 100%;
  border-radius: 8px;
  background-color: ${CLR_BG3};
  padding: 1rem;
  display: grid;
  grid-template-columns: calc(50% - 0.5rem) calc(50% - 0.5rem);
  gap: 1rem;
  @media (max-width: 968px) {
    flex-wrap: wrap;
    justify-content: center;
  }
  ${mobile({ gridTemplateColumns: "100%" })}
`
const Section = styled.div`
  width: 100%;
  padding: 1rem;
  background-color: white;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
`
const SectionData = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: ${CLR_BG1};
  div {
    font-size: 1.25rem;
    font-weight: 600;
  }
  span {
    font-size: 1.25rem;
    font-weight: 700;
  }
`
const SectionIcon = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  svg {
    fill: ${CLR_BG1};
    font-size: 2.5rem;
  }
`

const CouponsStats = ({ orders }) => {
  return (
    <Container>
      <Section>
        <SectionData>
          <div>Uses On Web</div>
          {orders.length > 0 ? (
            <span>{orders.filter(el => el.trackId.length === 8).length}</span>
          ) : (
            <span>0</span>
          )}
        </SectionData>
        <SectionIcon>
          <LaptopWindowsIcon />
        </SectionIcon>
      </Section>
      <Section>
        <SectionData>
          <div>Uses On Mobile</div>
          {orders.length > 0 ? (
            <span>{orders.filter(el => el.trackId.length === 10).length}</span>
          ) : (
            <span>0</span>
          )}
        </SectionData>
        <SectionIcon>
          <TabletAndroidIcon />
        </SectionIcon>
      </Section>
      <Section>
        <SectionData>
          <div>Last Time Used</div>
          {orders.length > 0 ? (
            <span>
              {new Date(
                orders[orders.length - 1].createdAt
              ).toLocaleDateString()}
            </span>
          ) : (
            <span>-</span>
          )}
        </SectionData>
        <SectionIcon>
          <AccessTimeIcon />
        </SectionIcon>
      </Section>
      <Section>
        <SectionData>
          <div>Total Discounts</div>
          {orders.length > 0 ? (
            <span>{getTotalDiscounts(orders)}</span>
          ) : (
            <span>0</span>
          )}
        </SectionData>
        <SectionIcon>
          <AttachMoneyIcon />
        </SectionIcon>
      </Section>
    </Container>
  )
}

export default CouponsStats
