import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import Root from "./Root"
import MainActionsPage from "./pages/MainActionsPage"
import UsersPage from "./pages/UsersPage"
import SalesPage from "./pages/SalesPage"
import QuickActionsPage from "./pages/QuickActionsPage"
import StatisticesPage from "./pages/StatisticesPage"
import ReportsPage from "./pages/ReportsPage"
import TicketsPage from "./pages/TicketsPage";
import BlogPage from "./pages/BlogPage";
import CouponsPage from "./pages/CouponsPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <div>invalid page</div>,
    children: [
      {
        path: "/statistices",
        element: <StatisticesPage />,
      },
      {
        path: "/main-actions",
        element: <MainActionsPage />,
      },
      {
        path: "/quick-actions",
        element: <QuickActionsPage />,
      },
      {
        path: "/reports",
        element: <ReportsPage />,
      },
      {
        path: "/tickets",
        element: <TicketsPage />,
      },
      {
        path: "/banned-users",
        element: <UsersPage />,
      },
      {
        path: "/sales",
        element: <SalesPage />,
      },
      {
        path: "/blog",
        element: <BlogPage />,
      },
      {
        path: "/coupons",
        element: <CouponsPage />,
      },
    ],
  },
])

const App = () => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <RouterProvider router={router} />
    </LocalizationProvider>
  )
}

export default App
