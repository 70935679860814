import styled from "styled-components"
import { useSelector } from "react-redux"
import { useState } from "react"
import CouponsHeader from "../components/CouponsHeader"
import CouponsStats from "../components/CouponsStats"
import CouponsData from "../components/CouponsData"

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`

const CouponsPage = () => {
  const { currentUser } = useSelector(state => state.user)
  const [coupon, setCoupon] = useState(null)
  const [orders, setOrders] = useState([])

  if (currentUser.access === "support" || currentUser.access === "blog") return <></>
  return (
    <Container>
      <CouponsHeader setCoupon={setCoupon} setOrders={setOrders} />
      <CouponsStats coupon={coupon} orders={orders} />
      <CouponsData coupon={coupon} orders={orders} />
    </Container>
  )
}

export default CouponsPage
