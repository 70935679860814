import styled from "styled-components"
import { CLR_BG1, CLR_BG3, CLR_LT2 } from "../app/constants"
import { mobile } from "../app/responsive"

const DATA = [
  { id: "coupon", title: "Coupon" },
  { id: "percentage", title: "Percentage" },
  { id: "max", title: "Maximum Amount" },
  { id: "oneUse", title: "One Use" },
  { id: "uses", title: "Total Uses" },
  { id: "createdAt", title: "Creation Date" },
]

const Container = styled.div`
  width: 100%;
  display: flex;
  gap: 1rem;
  @media (max-width: 968px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`
const DataWrapper = styled.div`
  width: 50%;
  border-radius: 8px;
  background-color: ${CLR_BG3};
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  ${mobile({ width: "100%" })}
`
const Title = styled.div`
  font-size: 1.5rem;
  font-weight: 700;
  color: ${CLR_BG1};
`
const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  background-color: white;
`
const Row = styled.tr`
  background-color: ${props => (props.i % 2 === 0 ? "white" : CLR_LT2)};
`
const Header = styled.th`
  font-size: 1.25rem;
  font-weight: 700;
  white-space: nowrap;
  text-align: left;
  padding: 1rem;
`
const Cell = styled.td`
  font-size: 1rem;
  font-weight: 600;
  white-space: nowrap;
  padding: 1rem;
`

const CouponsData = ({ coupon, orders }) => {
  return (
    <Container>
      <DataWrapper>
        <Title>Coupon Details</Title>
        <Table>
          {DATA.map((el, i) => (
            <Row i={i}>
              <Header>{el.title}</Header>
              <Cell>{coupon ? JSON.stringify(coupon[el.id]) : "-"}</Cell>
            </Row>
          ))}
        </Table>
      </DataWrapper>
      <DataWrapper>
        <Title>Recents</Title>
        <Table>
          {orders.length > 0 ? (
            <>
              {orders.slice(0, 7).map((el, i) => (
                <Row i={0}>
                  <Cell>
                    {el.userEmail +
                      " - " +
                      new Date(el.createdAt).toLocaleString()}
                  </Cell>
                </Row>
              ))}
            </>
          ) : (
            <Row i={0}>
              <Cell>-</Cell>
            </Row>
          )}
        </Table>
      </DataWrapper>
    </Container>
  )
}

export default CouponsData
